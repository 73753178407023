<template>
  <section>
    <b-container fluid>
      <b-row>
        <b-col xl="8" lg="8" cols="12">
          <b-card-actions
              class="list-card"
              ref="stok-listesi"
              @refresh="refreshStop('list')"
          >
            <b-row class="mt-50">
              <div class="card-header-row" :style="{backgroundColor: $store.getters.COLORS_GET.cardTitle}">
                <b-col cols="12">
                  <h4 class="text-white d-inline-block card-title">Depolar</h4>
                </b-col>
              </div>
            </b-row>
            <table class="table table-hover table-sm mt-5">
              <thead>
              <tr>
                <th
                    :style="{backgroundColor: $store.getters.COLORS_GET.tableTitle +'!important',color:'white!important'}"
                    scope="col"
                >Depo Adı
                </th>
                <th
                    :style="{backgroundColor: $store.getters.COLORS_GET.tableTitle +'!important',color:'white!important'}"
                    scope="col"
                >Kayıtlı Ürün Sayısı
                </th>
                <th
                    :style="{backgroundColor: $store.getters.COLORS_GET.tableTitle +'!important',color:'white!important'}"
                    scope="col"
                ></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item ,i) in depoListesi" :key="i">
                <td style="width: 60%" @click="$router.push({ name: 'depoDetay', params: { id: item.depoID } })">
                  {{ item.depoAdi }}
                </td>
                <td style="width: 20%" @click="$router.push({ name: 'depoDetay', params: { id: item.depoID } })">
                  {{ item.toplamStok }}
                </td>
                <td style="width: 20%">
                  <b-row class="d-flex justify-content-end">
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="success"
                        class="btn-icon rounded-circle mr-50"
                        v-b-tooltip.left="'Depo Detay'"
                        size="sm"
                        @click="$router.push({ name: 'depoDetay', params: { id: item.depoID } })"
                        :style="{backgroundColor:$store.getters.COLORS_GET.bgInfo+'!important',border:'none'}"
                    >
                      <font-awesome-icon icon="fa-solid fa-boxes-stacked"/>
                    </b-button>
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="warning"
                        class="btn-icon rounded-circle mr-50"
                        v-b-tooltip.right="'Düzenle'"
                        size="sm"
                        @click="updateStepOne(item)"
                        :style="{backgroundColor:$store.getters.COLORS_GET.bgWarning+'!important',border:'none'}"
                    >
                      <font-awesome-icon icon="fa-solid fa-pen"/>
                    </b-button>
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="danger"
                        class="btn-icon rounded-circle mr-50"
                        v-b-tooltip.right="'Sil'"
                        size="sm"
                        @click="remove(item.depoID)"
                        :style="{backgroundColor:$store.getters.COLORS_GET.bgDanger+'!important',border:'none'}"
                    >
                      <font-awesome-icon icon="fa-solid fa-trash-can"/>
                    </b-button>
                  </b-row>
                </td>
              </tr>
              </tbody>
            </table>
            <hr/>
            <b-row class="d-flex align-items-center mt-1">
              <b-col cols="12">
                <p class="text-muted">Toplam {{ toplamKayit }} Kayıt bulundu</p>
              </b-col>
            </b-row>
          </b-card-actions>
        </b-col>
        <b-col xl="4" lg="4" cols="12">
          <b-card-actions
              class="list-card"
              ref="stok-listesi"
              @refresh="refreshStop('list')"
          >
            <b-row class="mt-50">
              <div class="card-header-row"
                   :style="{backgroundColor: !edit ? $store.getters.COLORS_GET.cardTitle : 'orange'}"
              >
                <b-col cols="12">
                  <h4 class="text-white d-inline-block card-title">{{
                      !edit ? 'Depo Kayıt' : oldName + ' Adlı Depo Düzenleniyor'
                    }}</h4>
                </b-col>
              </div>
            </b-row>
            <b-form @submit.prevent class="mt-5">
              <b-row class="top-1110">
                <b-col cols="12">
                  <b-form-group
                      label="Depo Adı"
                      label-for="vi-first-name"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <font-awesome-icon icon="fa-solid fa-warehouse"/>
                      </b-input-group-prepend>
                      <b-form-input
                          id="vi-first-name"
                          autocomplete="off"
                          v-model="name"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col class="d-flex justify-content-end">
                  <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="primary"
                      class="mr-50"
                      @click="!edit  ? save() : updateStepSecond()"
                      :disabled="!edit && !name"
                      :style="{backgroundColor:$store.getters.COLORS_GET.bgSuccess+'!important',border:'none'}"
                  >
                    {{ !edit ? 'Kaydet' : 'Düzenle' }}
                  </b-button>
                  <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="danger"
                      @click="edit = false"
                      v-if="edit"
                      :style="{backgroundColor:$store.getters.COLORS_GET.bgDanger+'!important',border:'none'}"
                  >
                    Vazgeç
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </b-card-actions>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import {
  BContainer,
  BModal,
  BFormSelect,
  BInputGroupAppend,
  BPagination,
  VBTooltip,
  BBadge,
  BTable,
  BRow, BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BInputGroup,
  BInputGroupPrepend,
  BForm,
  BButton,
  BCardHeader,
  BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import Ripple from 'vue-ripple-directive'
import 'vue-search-select/dist/VueSearchSelect.css'
import { ModelListSelect } from 'vue-search-select/dist/VueSearchSelect.common'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import vMultiselectListbox from 'vue-multiselect-listbox-v2'
import 'vue-multiselect-listbox-v2/dist/vue-multi-select-listbox.css'
import 'vue-multiselect-listbox-v2/src/scss/vue-multi-select-listbox.scss'

export default {
  components: {
    BContainer,
    vMultiselectListbox,
    BModal,
    BFormSelect,
    BInputGroupAppend,
    BPagination,
    BBadge,
    BTable,
    BListGroup,
    BListGroupItem,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BInputGroup,
    BInputGroupPrepend,
    BForm,
    BButton,
    BRow,
    BCol,
    BCardHeader,
    BCardActions,
    ModelListSelect,
  },
  data() {
    return {
      edit: false,
      updateID: '',
      name: '',
      oldName: '',
      toplamKayit: '',
    }
  },
  computed: {
    depoListesi() {
      return store.getters.DEPO_LIST_GET
    },
  },
  watch: {
    edit(val) {
      if (val == false) {
        this.name = ''
      }
    },
  },
  methods: {
    updateStepOne(depo) {
      this.edit = true
      this.name = depo.depoAdi
      this.oldName = depo.depoAdi
      this.updateID = depo.depoID
    },
    updateStepSecond() {
      store.dispatch('depoUpdate', {
        depoID: this.updateID,
        depoAdi: this.name,
      })
          .then((res, position = 'bottom-right') => {
            this.edit = false
            this.updateID = ''
            this.name = ''
            this.oldName = ''
            store.dispatch('depoListesi')
                .then(result => {
                  const liste = { ...result }
                  delete liste.userToken
                  this.toplamKayit = Object.keys(liste).length
                })
            const mesaj = store.getters.notificationSettings(res)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Başarılı',
                  text: mesaj,
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              }, { position })
            }
          })
          .catch(err => {
            const mesaj = store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    remove(depoID) {
      store.dispatch('depoRemove', depoID)
          .then((res, position = 'bottom-right') => {
            store.dispatch('depoListesi')
                .then(res => {
                  const liste = { ...res }
                  delete liste.userToken
                  this.toplamKayit = Object.keys(liste).length
                })
            const mesaj = store.getters.notificationSettings(res)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Başarılı',
                  text: mesaj,
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              }, { position })
            }
          })
          .catch(err => {
            const mesaj = store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    save() {
      store.dispatch('depoSave', this.name)
          .then((res, position = 'bottom-right') => {
            this.name = ''
            store.dispatch('depoListesi')
                .then(result => {
                  const liste = { ...result }
                  delete liste.userToken
                  this.toplamKayit = Object.keys(liste).length
                })
            const mesaj = store.getters.notificationSettings(res)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Başarılı',
                  text: mesaj,
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              }, { position })
            }
          })
          .catch(err => {
            const mesaj = store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    refreshStop(cardName) {
      setTimeout(() => {
        this.$refs[cardName || 'list'].showLoading = false
      }, 1000)
    },
  },
  created() {
    store.dispatch('depoListesi')
        .then(res => {
          const liste = { ...res }
          delete liste.userToken
          this.toplamKayit = Object.keys(liste).length
        })
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
}
</script>

<style scoped>
@media (max-width: 1110px) {
  .top-1110 {
    margin-top: 5rem;
  }
}
</style>
